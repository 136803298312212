import React, { useState, useEffect } from 'react';
import API from '../services/api';
import { Table, Spinner, Alert, Pagination, Form, Button, InputGroup } from 'react-bootstrap';

const UserIpPermissions = () => {
  const [assignedIps, setAssignedIps] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState(''); // 搜索条件
  const limit = 10; // 每页显示10条数据

  // 获取当前用户分配的IP列表
  const fetchAssignedIps = async (page = 1, search = '') => {
    setLoading(true);
    setError(null);
    try {
      const response = await API.get(`/api/user/assigned-ips?page=${page}&limit=${limit}&search=${search}`);
      setAssignedIps(response.data.assignedIps); // 按照数据库顺序设置IP列表
      setTotalPages(response.data.totalPages);
      setCurrentPage(response.data.currentPage);
      setLoading(false);
    } catch (error) {
      setError('无法获取IP数据');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAssignedIps(currentPage, searchTerm); // 页面加载时获取数据
  }, [currentPage, searchTerm]);

  // 处理搜索输入变化
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // 搜索时重置为第一页
  };

  return (
    <div className="container mt-4">
      <h1 className="text-center mb-4">我的IP权限</h1>
      
      <Form className="d-flex justify-content-center mb-4">
        <InputGroup className="w-50">
          <Form.Control
            type="text"
            placeholder="搜索IP地址"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <Button variant="primary" onClick={() => fetchAssignedIps(1, searchTerm)}>搜索</Button>
        </InputGroup>
      </Form>

      {loading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">加载中...</span>
          </Spinner>
        </div>
      ) : error ? (
        <Alert variant="danger">{error}</Alert>
      ) : (
        <>
          {assignedIps.length > 0 ? (
            <>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>IP地址</th>
                  </tr>
                </thead>
                <tbody>
                  {assignedIps.map((ip, index) => (
                    <tr key={index}>
                      <td>{(currentPage - 1) * limit + index + 1}</td>
                      <td>{ip}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              {/* 分页控件 */}
              <Pagination className="justify-content-center">
                <Pagination.First onClick={() => setCurrentPage(1)} disabled={currentPage === 1} />
                <Pagination.Prev onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1} />
                {[...Array(totalPages)].map((_, pageIndex) => (
                  <Pagination.Item
                    key={pageIndex + 1}
                    active={pageIndex + 1 === currentPage}
                    onClick={() => setCurrentPage(pageIndex + 1)}
                  >
                    {pageIndex + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === totalPages} />
                <Pagination.Last onClick={() => setCurrentPage(totalPages)} disabled={currentPage === totalPages} />
              </Pagination>
            </>
          ) : (
            <Alert variant="info">当前没有分配的IP。</Alert>
          )}
        </>
      )}
    </div>
  );
};

export default UserIpPermissions;
