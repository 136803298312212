import { Route, Routes, Navigate, Link, useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; // 如果需要解析和验证 JWT token
import AdminDashboard from './pages/AdminDashboard';
import UserDashboard from './pages/UserDashboard';
import UserManagement from './pages/UserManagement';
import IpAllocation from './pages/IpAllocation';
import TrafficQuery from './pages/TrafficQuery';
import Login from './pages/Login';
import UserIpPermissions from './pages/UserIpPermissions';
import ConnectionQuery from './pages/ConnectionQuery';
import AdminAttackQuery from './pages/AdminAttackQuery';
import UserAttackQuery from './pages/UserAttackQuery';
import IPPolicyAdjustment from './pages/IPPolicyAdjustment';
import IPPolicyAdjustmentUser from './pages/IPPolicyAdjustmentUser';
import UserConnectionQuery from './pages/UserConnectionQuery'; // 导入新的用户查询页面
import 'bootstrap/dist/css/bootstrap.min.css';

// 检查 token 是否有效
const isAuthenticated = () => {
  const token = localStorage.getItem('token');
  if (!token) return false;

  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000; // 当前时间戳 (秒)
    
    // 检查 token 是否过期
    if (decodedToken.exp < currentTime) {
      localStorage.removeItem('token');
      localStorage.removeItem('role');
      return false;
    }

    return true; // token 有效
  } catch (err) {
    // 如果 token 解码失败，认为 token 无效
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    return false;
  }
};

// 获取用户角色
const getUserRole = () => {
  const token = localStorage.getItem('token');
  if (!token) return null;
  return localStorage.getItem('role');
};

function App() {
  const navigate = useNavigate();

  // 退出登录处理
  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    navigate('/login');
  };

  return (
    <div className="d-flex flex-column min-vh-100">
      <header>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid">
            <Link className="navbar-brand" to="/">DDoS 监控系统</Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                {isAuthenticated() && getUserRole() === 'admin' && (
                  <>
                    <li className="nav-item">
                      <Link className="nav-link" to="/admin">管理员首页</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/user-management">用户管理</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/ip-allocation">IP 分配</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/traffic-query">流量查询</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/ip-connection-query">IP连接数查询</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/ip-attack-query">IP攻击记录查询</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/ip-policy-adjustment">IP策略调整</Link>
                    </li>
                  </>
                )}
                {isAuthenticated() && getUserRole() === 'user' && (
                  <>
                    <li className="nav-item">
                      <Link className="nav-link" to="/user">用户首页</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/user-ip-permissions">我的IP权限</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/user-ip-connection-query">我的IP连接数查询</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/user-ip-attack-query">我的IP攻击记录查询</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/user-ip-policy-adjustment">我的IP策略调整</Link>
                    </li>
                  </>
                )}
              </ul>
              <ul className="navbar-nav ms-auto">
                {isAuthenticated() && (
                  <li className="nav-item">
                    <button className="btn btn-outline-danger" onClick={handleLogout}>退出登录</button>
                  </li>
                )}
                {!isAuthenticated() && (
                  <li className="nav-item">
                    <Link className="nav-link" to="/login">登录</Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </nav>
      </header>

      <main className="flex-grow-1">
        <Routes>
          {/* 根路由，根据用户角色跳转到各自首页 */}
          <Route path="/" element={<RootRedirect />} />
          <Route path="/login" element={<Login />} />
          <Route path="/admin" element={<ProtectedRoute element={<AdminDashboard />} role="admin" />} />
          <Route path="/user-management" element={<ProtectedRoute element={<UserManagement />} role="admin" />} />
          <Route path="/user" element={<ProtectedRoute element={<UserDashboard />} role="user" />} />
          <Route path="/user-ip-permissions" element={<ProtectedRoute element={<UserIpPermissions />} role="user" />} />
          <Route path="/ip-allocation" element={<ProtectedRoute element={<IpAllocation />} role="admin" />} />
          <Route path="/traffic-query" element={<ProtectedRoute element={<TrafficQuery />} role="admin" />} />
          <Route path="/ip-connection-query" element={<ProtectedRoute element={<ConnectionQuery />} role="admin" />} />
          <Route path="/ip-attack-query" element={<ProtectedRoute element={<AdminAttackQuery />} role="admin" />} />
          <Route path="/user-ip-connection-query" element={<ProtectedRoute element={<UserConnectionQuery />} role="user" />} />
          <Route path="/user-ip-attack-query" element={<ProtectedRoute element={<UserAttackQuery />} role="user" />} />
          <Route path="/ip-policy-adjustment" element={<ProtectedRoute element={<IPPolicyAdjustment />} role="admin" />} />
          <Route path="/user-ip-policy-adjustment" element={<ProtectedRoute element={<IPPolicyAdjustmentUser />} role="user" />} />
        </Routes>
      </main>
    </div>
  );
}

// 根路由跳转组件，根据用户角色跳转到相应的首页
const RootRedirect = () => {
  const isLoggedIn = isAuthenticated();
  const userRole = getUserRole();

  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }

  if (userRole === 'admin') {
    return <Navigate to="/admin" />;
  } else if (userRole === 'user') {
    return <Navigate to="/user" />;
  }

  return <Navigate to="/login" />;
};

// 受保护的路由组件，根据登录状态和用户角色跳转
const ProtectedRoute = ({ element, role }) => {
  const isLoggedIn = isAuthenticated();
  const userRole = getUserRole();

  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }

  if (role && userRole !== role) {
    // 如果角色不匹配，禁止访问
    return <Navigate to="/" />;
  }

  return element; // 返回需要渲染的组件
};

export default App;
