import React, { useState, useEffect } from 'react';
import API from '../services/api'; // 导入统一的API实例
import { Modal, Button, Form, Pagination } from 'react-bootstrap';

const IpAllocation = () => {
  const [ips, setIps] = useState([]);
  const [username, setUsername] = useState('');
  const [ip, setIp] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedIps, setSelectedIps] = useState(new Set());
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalIps, setTotalIps] = useState(0); // 总IP数量
  const [limit] = useState(10); // 每页显示的IP数量
  const [alertModal, setAlertModal] = useState({ show: false, message: '', variant: '' }); // 控制提示弹窗

  // 获取所有 IP 地址
  const fetchIps = async () => {
    try {
      const response = await API.get('/api/admin/ips', {
        params: { search: searchTerm, page, limit },
      });
      setIps(response.data.ips);
      setTotalPages(response.data.totalPages);
      setTotalIps(response.data.totalIps); // 获取总IP数量
    } catch (error) {
      showAlert('获取IP列表失败', 'danger');
    }
  };

  useEffect(() => {
    fetchIps(); // Call fetchIps on component mount
  }, [searchTerm, page]);

  // 分配IP
  const handleAssignIp = async () => {
    try {
      await API.put('/api/admin/assign-ip', {
        username,
        ip,
      });
      showAlert('IP分配成功', 'success');
      setShowModal(false);
      setUsername('');
      setIp('');
      await fetchIps(); // Refresh IP list
    } catch (error) {
      showAlert('分配IP失败', 'danger');
    }
  };

  // 删除单个IP
  const handleDeleteIp = async (ipId) => {
    try {
      await API.delete(`/api/admin/ips/${ipId}`);
      showAlert('IP删除成功', 'success');
      await fetchIps(); // Refresh IP list
    } catch (error) {
      showAlert('删除IP失败', 'danger');
    }
  };

  // 批量删除IP
  const handleBulkDelete = async () => {
    const selectedIpsArray = Array.from(selectedIps);
    if (selectedIpsArray.length === 0) {
      showAlert('请选择要删除的IP', 'warning');
      return;
    }

    try {
      await API.delete('/api/admin/ips', { data: { ips: selectedIpsArray } });
      showAlert('批量删除成功', 'success');
      setSelectedIps(new Set());
      await fetchIps(); // Refresh IP list
    } catch (error) {
      showAlert('批量删除失败', 'danger');
    }
  };

  // 显示提示弹窗
  const showAlert = (message, variant) => {
    setAlertModal({ show: true, message, variant });
  };

  // 关闭提示弹窗
  const handleCloseAlert = () => {
    setAlertModal({ show: false, message: '', variant: '' });
  };

  // 搜索功能
  const filteredIps = ips.filter(ipObj => {
    const ipMatches = ipObj.ip.includes(searchTerm);
    const userMatches = ipObj.assignedTo && ipObj.assignedTo.username
      ? ipObj.assignedTo.username.includes(searchTerm)
      : false;
    return ipMatches || userMatches;
  });

  // 分页按钮
  const paginationItems = [];
  for (let i = 1; i <= totalPages; i++) {
    paginationItems.push(
      <Pagination.Item key={i} active={i === page} onClick={() => setPage(i)}>
        {i}
      </Pagination.Item>
    );
  }

  return (
    <div className="container mt-4">
      <h1>IP 分配</h1>
      <div className="d-flex justify-content-between mb-3">
        <div>
          <p>总IP数量：{totalIps}</p>
        </div>
        <div>
          <input
            type="text"
            className="form-control"
            placeholder="搜索用户名或IP"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      <div className="d-flex justify-content-between mb-3">
        <h2>IP列表</h2>
        <div>
          <Button variant="danger" className="me-2" onClick={handleBulkDelete}>
            批量删除
          </Button>
          <Button variant="primary" onClick={() => setShowModal(true)}>
            分配 IP
          </Button>
        </div>
      </div>

      <table className="table table-striped">
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                onChange={(e) => {
                  const checked = e.target.checked;
                  const newSelectedIps = new Set();
                  if (checked) {
                    filteredIps.forEach(ipObj => newSelectedIps.add(ipObj.ip));
                  }
                  setSelectedIps(newSelectedIps);
                }}
              />
            </th>
            <th>IP 地址</th>
            <th>分配给用户</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          {filteredIps.map((ipObj) => (
            <tr key={ipObj._id}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedIps.has(ipObj.ip)}
                  onChange={() => {
                    const newSelectedIps = new Set(selectedIps);
                    if (newSelectedIps.has(ipObj.ip)) {
                      newSelectedIps.delete(ipObj.ip);
                    } else {
                      newSelectedIps.add(ipObj.ip);
                    }
                    setSelectedIps(newSelectedIps);
                  }}
                />
              </td>
              <td>{ipObj.ip}</td>
              <td>{ipObj.assignedTo ? ipObj.assignedTo.username : '未分配'}</td>
              <td>
                <Button variant="danger" onClick={() => handleDeleteIp(ipObj._id)}>
                  删除
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* 分配 IP 的弹窗 */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>分配 IP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formUsername">
              <Form.Label>用户名</Form.Label>
              <Form.Control
                type="text"
                placeholder="输入用户名"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formIp">
              <Form.Label>IP 地址</Form.Label>
              <Form.Control
                type="text"
                placeholder="输入IP地址"
                value={ip}
                onChange={(e) => setIp(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            关闭
          </Button>
          <Button variant="primary" onClick={handleAssignIp}>
            分配
          </Button>
        </Modal.Footer>
      </Modal>

      {/* 分页控件 */}
      <div className="d-flex justify-content-between mt-4">
        <Pagination>
          {paginationItems}
        </Pagination>
        <span>当前第 {page} 页，共 {totalPages} 页</span>
      </div>

      {/* 提示信息弹窗 */}
      <Modal show={alertModal.show} onHide={handleCloseAlert}>
        <Modal.Header closeButton>
          <Modal.Title>提示</Modal.Title>
        </Modal.Header>
        <Modal.Body className={`text-${alertModal.variant}`}>
          {alertModal.message}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAlert}>
            关闭
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default IpAllocation;
