import React, { useEffect, useState, useCallback } from 'react';
import API from '../services/api'; // 导入统一的API实例
import * as echarts from 'echarts'; // 导入ECharts
import './AdminDashboard.css'; // 导入自定义样式

const AdminDashboard = () => {
  const [userCount, setUserCount] = useState(0);
  const [assignedIpCount, setAssignedIpCount] = useState(0);
  const [trafficData, setTrafficData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userResponse = await API.get('/api/admin/user-count');
        setUserCount(userResponse.data.userCount);

        const ipResponse = await API.get('/api/admin/assigned-ip-count');
        setAssignedIpCount(ipResponse.data.assignedIpCount);

        const dataResponse = await API.get('/api/admin/total-traffic');
        setTrafficData(dataResponse.data.totalTraffic);
      } catch (error) {
        console.error('获取数据失败:', error);
      }
    };

    fetchData();
  }, []);

  // 将 `drawTrafficChart` 函数包装在 `useCallback` 中，以便在依赖项更改时不会重新创建该函数
  const drawTrafficChart = useCallback((chart, dataArray) => {
    const labels = dataArray.map(data => `${data.hour}:${data.minute}`);
    const inSizeRaw = dataArray.map(data => (data.in_size * 8));
    const outSizeRaw = dataArray.map(data => (data.out_size * 8));

    const maxValue = Math.max(...inSizeRaw, ...outSizeRaw);
    const { divisor, unit } = getUnit(maxValue);

    const inSize = inSizeRaw.map(size => size / divisor);
    const outSize = outSizeRaw.map(size => size / divisor);

    const trafficOption = {
      tooltip: {
        trigger: 'axis',
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        borderColor: '#333',
        textStyle: {
          color: '#fff'
        }
      },
      grid: {
        left: isMobile ? '15%' : '10%',
        right: isMobile ? '10%' : '10%',
        bottom: isMobile ? '20%' : '10%',
        containLabel: true
      },
      legend: {
        data: [`输入流量 (${unit})`, `输出流量 (${unit})`],
        bottom: '0%',
        textStyle: {
          fontSize: isMobile ? 10 : 14, // 手机端减少字体大小
          color: '#333'
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: labels,
        axisLine: {
          lineStyle: {
            color: '#333'
          }
        },
        axisLabel: {
          color: '#333',
          fontSize: isMobile ? 10 : 14 // 根据屏幕大小调整字体
        }
      },
      yAxis: {
        type: 'value',
        axisLine: {
          lineStyle: {
            color: '#333'
          }
        },
        axisLabel: {
          formatter: `{value} ${unit}`,
          color: '#333',
          fontSize: isMobile ? 10 : 14 // 根据屏幕大小调整字体
        }
      },
      series: [
        {
          name: `输入流量 (${unit})`,
          type: 'line',
          data: inSize,
          smooth: true,
          lineStyle: {
            width: 3,
            color: '#007bff'
          },
          areaStyle: {
            color: 'rgba(0, 123, 255, 0.2)'
          }
        },
        {
          name: `输出流量 (${unit})`,
          type: 'line',
          data: outSize,
          smooth: true,
          lineStyle: {
            width: 3,
            color: '#28a745'
          },
          areaStyle: {
            color: 'rgba(40, 167, 69, 0.2)'
          }
        }
      ]
    };
    chart.setOption(trafficOption);
  }, []);

  // 将 `drawConnectionChart` 函数包装在 `useCallback` 中
  const drawConnectionChart = useCallback((chart, dataArray) => {
    const labels = dataArray.map(data => `${data.hour}:${data.minute}`);
    const maxInCount = dataArray.map(data => data.average_connect_in_count);

    const connectionOption = {
      tooltip: {
        trigger: 'axis',
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        borderColor: '#333',
        textStyle: {
          color: '#fff'
        }
      },
      grid: {
        left: isMobile ? '15%' : '10%',
        right: isMobile ? '10%' : '10%',
        bottom: isMobile ? '20%' : '10%',
        containLabel: true
      },
      legend: {
        data: ['最大输入并发连接'],
        bottom: '0%',
        textStyle: {
          fontSize: isMobile ? 10 : 14,
          color: '#333'
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: labels,
        axisLine: {
          lineStyle: {
            color: '#333'
          }
        },
        axisLabel: {
          color: '#333',
          fontSize: isMobile ? 10 : 14 // 根据屏幕大小调整字体
        }
      },
      yAxis: {
        type: 'value',
        axisLine: {
          lineStyle: {
            color: '#333'
          }
        },
        axisLabel: {
          color: '#333',
          fontSize: isMobile ? 10 : 14 // 根据屏幕大小调整字体
        }
      },
      series: [
        {
          name: '最大输入并发连接',
          type: 'line',
          data: maxInCount,
          smooth: true,
          lineStyle: {
            width: 3,
            color: '#ff6f61'
          },
          areaStyle: {
            color: 'rgba(255, 111, 97, 0.2)'
          }
        }
      ]
    };
    chart.setOption(connectionOption);
  }, []);

  const isMobile = window.innerWidth <= 768;

  const getUnit = (maxValue) => {
    if (maxValue >= 1e9) return { divisor: 1e9, unit: 'Gbps' };
    if (maxValue >= 1e6) return { divisor: 1e6, unit: 'Mbps' };
    if (maxValue >= 1e3) return { divisor: 1e3, unit: 'Kbps' };
    return { divisor: 1, unit: 'bps' };
  };

  useEffect(() => {
    if (trafficData.length > 0) {
      const trafficChart = echarts.init(document.getElementById('trafficChart'));
      const connectionChart = echarts.init(document.getElementById('connectionChart'));

      drawTrafficChart(trafficChart, trafficData);
      drawConnectionChart(connectionChart, trafficData);

      window.addEventListener('resize', () => {
        trafficChart.resize();
        connectionChart.resize();
      });

      return () => {
        trafficChart.dispose();
        connectionChart.dispose();
        window.removeEventListener('resize', () => {
          trafficChart.resize();
          connectionChart.resize();
        });
      };
    }
  }, [trafficData, drawTrafficChart, drawConnectionChart]);

  return (
    <div className="admin-dashboard container">
      <h1 className="text-center mt-4">管理员首页</h1>
      <div className="row mt-5">
        <div className="col-md-6 mb-3">
          <div className="info-block p-4 shadow-sm bg-light rounded">
            <p>用户数量: <strong>{userCount}</strong></p>
          </div>
        </div>
        <div className="col-md-6 mb-3">
          <div className="info-block p-4 shadow-sm bg-light rounded">
            <p>已分配IP数量: <strong>{assignedIpCount}</strong></p>
          </div>
        </div>
      </div>
      {/* 添加图表显示流量 */}
      <div className="row">
        <div className="col-md-12 mb-4">
          <div className="chart-container shadow-sm p-3 bg-white rounded" id="trafficChart" style={{ height: '400px', width: '100%' }}></div>
        </div>
        <div className="col-md-12 mb-4">
          <div className="chart-container shadow-sm p-3 bg-white rounded" id="connectionChart" style={{ height: '400px', width: '100%' }}></div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
