import React, { useState } from 'react';
import { Button, Form, Container, Row, Col, Table, Alert, OverlayTrigger, Tooltip, Pagination } from 'react-bootstrap';
import API from '../services/api'; // 导入统一的API实例

const UserAttackQuery = () => {
  const [ip, setIp] = useState('');  // 用户输入的IP地址
  const [attackLogs, setAttackLogs] = useState([]); // 存储攻击日志数据
  const [errorMessage, setErrorMessage] = useState(''); // 错误信息
  const [showAlert, setShowAlert] = useState(false); // 控制提示框显示
  const [loading, setLoading] = useState(false); // 控制加载动画
  const [currentPage, setCurrentPage] = useState(1); // 当前页码
  const logsPerPage = 10; // 每页显示的日志数量
  const [hasSearched, setHasSearched] = useState(false); // 控制是否显示查询结果

  // 查询攻击日志的处理函数
  const handleAttackQuery = async () => {
    setLoading(true); // 开启加载动画
    setHasSearched(false); // 重置查询状态
    try {
      // 调用API获取攻击日志数据
      const response = await API.get('/api/user/ip-attack-lookup', {
        params: { ip },  // 传递IP地址作为查询参数
      });

      const logs = response.data.attackLogs;
      if (logs.length === 0) {
        setErrorMessage(`没有找到与源IP ${ip} 匹配的攻击日志`);
        setShowAlert(true);
        setAttackLogs([]);  // 如果没有匹配的数据，清空attackLogs
      } else {
        setAttackLogs(logs); // 保存匹配到的日志数据
        setShowAlert(false);
        setCurrentPage(1); // 每次查询后重置页码为1
      }
      setHasSearched(true); // 标记为已进行查询
    } catch (error) {
      // 从后端提取详细的错误信息
      const detailedErrorMessage = error.response?.data?.message || error.message || '获取攻击日志时出错';
      setErrorMessage(detailedErrorMessage); // 设置详细的错误信息
      setShowAlert(true);
    }
    setLoading(false); // 关闭加载动画
  };

  // 将持续时间转换为中文格式
  const convertDurationToChinese = (duration) => {
    const match = duration.match(/(\d+)(seconds?|minutes?|hours?)/);
    if (!match) return duration; // 如果未匹配到则返回原字符串

    const value = match[1];
    const unit = match[2];

    switch (unit) {
      case 'second':
      case 'seconds':
        return `${value}秒`;
      case 'minute':
      case 'minutes':
        return `${value}分钟`;
      case 'hour':
      case 'hours':
        return `${value}小时`;
      default:
        return duration;
    }
  };

  // 将Mbps流量转换为Gbps
  const convertToGbps = (sizeInMbps) => {
    return (sizeInMbps / 1000).toFixed(2); // 转换为Gbps，保留两位小数
  };

  // 分页逻辑，过滤出流量大于10Gbps的日志
  const logsAbove10Gbps = attackLogs.filter(log => convertToGbps(log.size) > 10);
  const indexOfLastLog = currentPage * logsPerPage;
  const indexOfFirstLog = indexOfLastLog - logsPerPage;
  const currentLogs = logsAbove10Gbps.slice(indexOfFirstLog, indexOfLastLog);

  const totalPages = Math.ceil(logsAbove10Gbps.length / logsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // 渲染表格数据，只显示流量大于10Gbps的日志
  const renderTable = () => {
    // 如果未进行查询则不显示表格
    if (!hasSearched) return null;

    // 如果没有大于10Gbps的数据
    if (logsAbove10Gbps.length === 0) {
      return <p>没有大于10Gbps的攻击日志。</p>;
    }

    return (
      <Table striped bordered hover responsive className="table-custom mt-3" style={{ tableLayout: 'fixed' }}>
        <thead className="thead-dark">
          <tr>
            <th style={{ width: '12%' }}>目标IP</th>
            <th style={{ width: '11%' }}>攻击源IP</th> {/* 缩略显示攻击源IP */}
            <th style={{ width: '15%' }}>攻击类型</th>
            <th style={{ width: '13%' }}>开始时间</th>
            <th style={{ width: '13%' }}>结束时间</th>
            <th style={{ width: '9%' }}>持续时间</th>
            <th style={{ width: '8%' }}>攻击包数</th>
            <th style={{ width: '8%' }}>丢弃包数</th>
            <th style={{ width: '12%' }}>流量 (Gbps)</th> {/* 修改为Gbps显示 */}
            <th style={{ width: '10%' }}>协议</th>
          </tr>
        </thead>
        <tbody>
          {currentLogs.map((log, index) => (
            <tr key={index}>
              <td className="nowrap">{log.s_ip}</td> {/* 目标IP */}
              <td className="nowrap">
                {/* 缩略攻击源IP，鼠标悬停显示完整的攻击源IP */}
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{log.c_ip_list}</Tooltip>}
                >
                  <span>
                    {log.c_ip_list.length > 15 ? `${log.c_ip_list.slice(0, 12)}...` : log.c_ip_list}
                  </span>
                </OverlayTrigger>
              </td>
              <td className="nowrap">{log.deftype}</td>
              <td className="nowrap">{log.start_time}</td>
              <td className="nowrap">{log.end_time}</td>
              <td className="nowrap">{convertDurationToChinese(log.duration)}</td> {/* 转换持续时间为中文 */}
              <td className="nowrap">{log.count}</td>
              <td className="nowrap">{log.drop_count}</td>
              <td className="nowrap">{convertToGbps(log.size)} Gbps</td> {/* 将流量从Mbps转换为Gbps */}
              <td className="nowrap">{log.packet_type}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  // 渲染分页控件
  const renderPagination = () => {
    if (logsAbove10Gbps.length === 0) return null;

    return (
      <Pagination className="justify-content-center mt-3">
        {Array.from({ length: totalPages }, (_, i) => (
          <Pagination.Item key={i + 1} active={i + 1 === currentPage} onClick={() => paginate(i + 1)}>
            {i + 1}
          </Pagination.Item>
        ))}
      </Pagination>
    );
  };

  return (
    <Container>
      <h1 className="my-4 text-center">用户攻击日志查询</h1>

      {/* 添加流量限制提示信息 */}
      <Row className="justify-content-center mb-3">
        <Col xs={12} className="text-center">
          <p>只显示流量大于 10Gbps 的攻击日志。</p>
        </Col>
      </Row>

      {/* 提示弹窗 */}
      <Alert variant="danger" show={showAlert} onClose={() => setShowAlert(false)} dismissible>
        {errorMessage}
      </Alert>

      {/* 输入IP查询部分 */}
      <Row className="justify-content-center mb-4">
        <Col xs={12} md={8}>
          <Form className="d-flex justify-content-center align-items-center">
            <Form.Control
              type="text"
              placeholder="输入查询IP地址"
              value={ip}
              onChange={(e) => setIp(e.target.value)}
              className="mr-3"
              style={{ width: '75%' }}
            />
            <Button variant="primary" className="px-4" onClick={handleAttackQuery} disabled={loading}>
              {loading ? '查询中...' : '查询'}
            </Button>
          </Form>
        </Col>
      </Row>

      {/* 表格展示攻击日志数据 */}
      <Row>
        <Col xs={12}>
          {renderTable()}
        </Col>
      </Row>

      {/* 分页控件 */}
      <Row>
        <Col xs={12}>
          {renderPagination()}
        </Col>
      </Row>
    </Container>
  );
};

export default UserAttackQuery;
