import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap'; // 引入Modal组件
import './Login.css';  // 引入针对登录页面的样式

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showAnnouncement, setShowAnnouncement] = useState(false); // 控制公告弹窗的状态
  const [showUpdateLog, setShowUpdateLog] = useState(false); // 控制更新日志弹窗的状态
  const navigate = useNavigate();
  
  // 定义版本号和更新日志
  const version = 'v1.0.8';
  const updateLog = `
  - 修复了登录失败时的错误提示问题
  - 增加了IP策略调整功能
  - 优化了用户界面的响应式设计
  - 改进了安全性，添加了CSRF防护机制
  - 更新公告功能现在更易于访问`;

  useEffect(() => {
    setShowAnnouncement(true); // 组件加载时显示公告
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
  
    try {
      const response = await axios.post(
        'https://ddos.data.setonink.com/api/auth/login',
        {
          username,
          password,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
  
      const { token, role } = response.data;
      localStorage.setItem('token', token);
      localStorage.setItem('role', role);
  
      if (role === 'admin') {
        navigate('/admin');
      } else {
        navigate('/user');
      }
    } catch (err) {
      console.error('登录失败:', err);
      setError('登录失败，请检查用户名和密码');
    }
  };  

  const handleCloseAnnouncement = () => setShowAnnouncement(false); // 关闭公告弹窗
  const handleCloseUpdateLog = () => setShowUpdateLog(false); // 关闭更新日志弹窗
  const handleShowUpdateLog = () => setShowUpdateLog(true); // 显示更新日志弹窗

  return (
    <div className="login-container">
      <div className="login-card">
        <h1 className="login-header">登录</h1>
        <form onSubmit={handleLogin}>
          <div className="mb-3">
            <label htmlFor="username" className="form-label">用户名</label>
            <input
              type="text"
              className="form-control"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="form-label">密码</label>
            <input
              type="password"
              className="form-control"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          {error && <p className="login-error-message">{error}</p>}
          <button type="submit" className="login-button">登录</button>
        </form>
      </div>
      
      {/* 公告弹窗 */}
      <Modal show={showAnnouncement} onHide={handleCloseAnnouncement} centered>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">重要公告</Modal.Title> {/* 标题标红 */}
        </Modal.Header>
        <Modal.Body className="text-center"> {/* 内容居中 */}
          本系统仅限购买云服务器/物理服务器时长≥3个月以及托管用户使用，获取账号请联系客服。
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAnnouncement}>
            关闭
          </Button>
        </Modal.Footer>
      </Modal>
      
      {/* 更新日志弹窗 */}
      <Modal show={showUpdateLog} onHide={handleCloseUpdateLog} centered>
        <Modal.Header closeButton>
          <Modal.Title>更新日志 - {version}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <pre>{updateLog}</pre> {/* 预格式化文本，保持换行 */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseUpdateLog}>
            关闭
          </Button>
        </Modal.Footer>
      </Modal>

      {/* 添加版权说明和超链接 */}
      <div className="support-text">
        本 DDoS 流量查询系统由
        <a href="https://www.11dun.com" target="_blank" rel="noopener noreferrer">壹盾安全</a>
        提供技术支持
      </div>

      {/* 版本号显示并可点击展示更新日志 */}
      <div className="version-text" onClick={handleShowUpdateLog} style={{ cursor: 'pointer' }}>
        系统版本号: {version} (点击查看更新内容)
      </div>
    </div>
  );
};

export default Login;
