import React, { useState } from 'react';
import { Form, Button, Alert, Container, Row, Col, Spinner } from 'react-bootstrap';
import API from '../services/api'; // 导入API实例

const IPPolicyAdjustment = () => {
  const [ip, setIp] = useState(''); // 存储IP地址
  const [message, setMessage] = useState(''); // 存储返回的消息
  const [error, setError] = useState(''); // 存储错误消息
  const [loading, setLoading] = useState(false); // 控制加载状态

  // 添加封UDP规则的处理函数
  const handleAddUDPBlock = async () => {
    setLoading(true);
    setMessage('');
    setError('');
    try {
      const response = await API.post('/api/admin/block-udp', { ip });
      setMessage(response.data.message); // 成功消息
    } catch (err) {
      setError(err.response?.data?.message || '添加封UDP规则时出错');
    }
    setLoading(false);
  };

  // 删除封UDP规则的处理函数
  const handleDeleteUDPBlock = async () => {
    setLoading(true);
    setMessage('');
    setError('');
    try {
      const response = await API.post('/api/admin/delete-block-udp', { ip });
      setMessage(response.data.message); // 成功消息
    } catch (err) {
      setError(err.response?.data?.message || '删除封UDP规则时出错');
    }
    setLoading(false);
  };

  // 添加封海外应用规则的处理函数
  const handleAddNonChinaBlock = async () => {
    setLoading(true);
    setMessage('');
    setError('');
    try {
      const response = await API.post('/api/admin/apply-rule-block-non-china', { ip });
      setMessage(response.data.message); // 成功消息
    } catch (err) {
      setError(err.response?.data?.message || '添加封海外应用规则时出错');
    }
    setLoading(false);
  };

  // 删除封海外应用规则的处理函数
  const handleDeleteNonChinaBlock = async () => {
    setLoading(true);
    setMessage('');
    setError('');
    try {
      const response = await API.post('/api/admin/delete-apply-rule-block-non-china', { ip });
      setMessage(response.data.message); // 成功消息
    } catch (err) {
      setError(err.response?.data?.message || '删除封海外应用规则时出错');
    }
    setLoading(false);
  };

  return (
    <Container>
      <h1 className="my-4 text-center">IP策略调整</h1>
      
      {/* 成功或错误提示 */}
      {message && <Alert variant="success">{message}</Alert>}
      {error && <Alert variant="danger">{error}</Alert>}

      <Row className="justify-content-center mb-4">
        <Col xs={12} md={8}>
          {/* 输入IP表单 */}
          <Form className="shadow p-4 mb-5 bg-white rounded">
            <Form.Group controlId="formIP">
              <Form.Label>IP地址</Form.Label>
              <Form.Control
                type="text"
                placeholder="请输入要操作的IP地址"
                value={ip}
                onChange={(e) => setIp(e.target.value)}
                className="mb-4"
              />
            </Form.Group>

            {/* 按钮组 */}
            <div className="d-flex justify-content-between flex-wrap">
              <Button
                variant="primary"
                onClick={handleAddUDPBlock}
                disabled={loading || !ip}
                className="px-4 mb-2"
              >
                {loading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{' '}
                    处理中...
                  </>
                ) : (
                  <>
                    <i className="fas fa-shield-alt"></i> 添加封UDP规则
                  </>
                )}
              </Button>

              <Button
                variant="danger"
                onClick={handleDeleteUDPBlock}
                disabled={loading || !ip}
                className="px-4 mb-2"
              >
                {loading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{' '}
                    处理中...
                  </>
                ) : (
                  <>
                    <i className="fas fa-trash-alt"></i> 删除封UDP规则
                  </>
                )}
              </Button>

              <Button
                variant="primary"
                onClick={handleAddNonChinaBlock}
                disabled={loading || !ip}
                className="px-4 mb-2"
              >
                {loading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{' '}
                    处理中...
                  </>
                ) : (
                  <>
                    <i className="fas fa-shield-alt"></i> 添加封海外规则
                  </>
                )}
              </Button>

              <Button
                variant="danger"
                onClick={handleDeleteNonChinaBlock}
                disabled={loading || !ip}
                className="px-4 mb-2"
              >
                {loading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{' '}
                    处理中...
                  </>
                ) : (
                  <>
                    <i className="fas fa-trash-alt"></i> 删除封海外规则
                  </>
                )}
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default IPPolicyAdjustment;
