import React, { useState, useEffect } from 'react';
import API from '../services/api'; // 导入统一的API实例
import { Modal, Button, Form, Row, Col, Spinner } from 'react-bootstrap'; // 引入Spinner组件
import * as echarts from 'echarts'; // 导入ECharts

const UserDashboard = () => {
  const [ip, setIp] = useState('');
  const [trafficData, setTrafficData] = useState(null);
  const [loading, setLoading] = useState(false); // 增加加载状态
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); // 用于显示后端的错误信息

  useEffect(() => {
    if (trafficData) {
      const trafficChart = echarts.init(document.getElementById('trafficChart'));
      const connectionChart = echarts.init(document.getElementById('connectionChart'));

      drawTrafficChart(trafficChart, trafficData);
      drawConnectionChart(connectionChart, trafficData);

      window.addEventListener('resize', () => {
        trafficChart.resize();
        connectionChart.resize();
      });

      // 清理事件和销毁图表实例
      return () => {
        trafficChart.dispose();
        connectionChart.dispose();
        window.removeEventListener('resize', () => {
          trafficChart.resize();
          connectionChart.resize();
        });
      };
    }
  }, [trafficData]);

  const handleIpTrafficQuery = async () => {
    setLoading(true); // 开始加载时，设置加载状态为true
    try {
      const response = await API.get(`/api/user/traffic/${ip}`);
      setTrafficData(response.data.ipTraffic);
      setLoading(false); // 数据加载完成，隐藏加载动画
    } catch (error) {
      console.error('获取单个IP流量失败:', error);
      setErrorMessage(error.response?.data?.message || '获取流量数据失败');
      setShowErrorModal(true);
      setLoading(false); // 如果失败，也隐藏加载动画
    }
  };

  const drawTrafficChart = (chart, dataArray) => {
    const labels = dataArray.map(data => `${data.hour}:${data.minute}`);
    const inSizeRaw = dataArray.map(data => data.in_size * 8);
    const outSizeRaw = dataArray.map(data => data.out_size * 8);

    const maxValue = Math.max(...inSizeRaw, ...outSizeRaw);
    const { divisor, unit } = getUnit(maxValue);

    const inSize = inSizeRaw.map(size => size / divisor);
    const outSize = outSizeRaw.map(size => size / divisor);

    const trafficOption = {
      grid: {
        left: '10%', // 增加左边距，确保Y轴显示完整
        right: '10%', // 增加右边距
        bottom: '10%',
        top: '10%',
        containLabel: true, // 确保图表不会溢出
      },
      tooltip: {
        trigger: 'axis',
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        borderColor: '#333',
        textStyle: {
          color: '#fff',
        },
      },
      legend: {
        data: [`输入流量 (${unit})`, `输出流量 (${unit})`],
        bottom: '0%',
        textStyle: {
          fontSize: 14,
          color: '#333',
        },
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: labels,
        axisLine: {
          lineStyle: {
            color: '#333',
          },
        },
        axisLabel: {
          color: '#333',
        },
      },
      yAxis: {
        type: 'value',
        axisLine: {
          lineStyle: {
            color: '#333',
          },
        },
        axisLabel: {
          formatter: `{value} ${unit}`,
          color: '#333',
          margin: 10, // 增加数值与Y轴的距离
          overflow: 'truncate', // 处理溢出文本
        },
      },
      series: [
        {
          name: `输入流量 (${unit})`,
          type: 'line',
          data: inSize,
          smooth: true,
          lineStyle: {
            width: 3,
            color: '#007bff',
          },
          areaStyle: {
            color: 'rgba(0, 123, 255, 0.2)',
          },
        },
        {
          name: `输出流量 (${unit})`,
          type: 'line',
          data: outSize,
          smooth: true,
          lineStyle: {
            width: 3,
            color: '#28a745',
          },
          areaStyle: {
            color: 'rgba(40, 167, 69, 0.2)',
          },
        },
      ],
    };
    chart.setOption(trafficOption);
  };

  const drawConnectionChart = (chart, dataArray) => {
    const labels = dataArray.map(data => `${data.hour}:${data.minute}`);
    const maxInCount = dataArray.map(data => data.average_connect_in_count);

    const connectionOption = {
      grid: {
        left: '10%', // 增加左边距，确保Y轴显示完整
        right: '10%', // 增加右边距
        bottom: '10%',
        top: '10%',
        containLabel: true, // 确保图表不会溢出
      },
      tooltip: {
        trigger: 'axis',
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        borderColor: '#333',
        textStyle: {
          color: '#fff',
        },
      },
      legend: {
        data: ['最大输入并发连接'],
        bottom: '0%',
        textStyle: {
          fontSize: 14,
          color: '#333',
        },
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: labels,
        axisLine: {
          lineStyle: {
            color: '#333',
          },
        },
        axisLabel: {
          color: '#333',
        },
      },
      yAxis: {
        type: 'value',
        axisLine: {
          lineStyle: {
            color: '#333',
          },
        },
        axisLabel: {
          color: '#333',
          margin: 10, // 增加数值与Y轴的距离
          overflow: 'truncate', // 处理溢出文本
        },
      },
      series: [
        {
          name: '最大输入并发连接',
          type: 'line',
          data: maxInCount,
          smooth: true,
          lineStyle: {
            width: 3,
            color: '#ff6f61',
          },
          areaStyle: {
            color: 'rgba(255, 111, 97, 0.2)',
          },
        },
      ],
    };
    chart.setOption(connectionOption);
  };

  const getUnit = (maxValue) => {
    if (maxValue >= 1e9) return { divisor: 1e9, unit: 'Gbps' };
    if (maxValue >= 1e6) return { divisor: 1e6, unit: 'Mbps' };
    if (maxValue >= 1e3) return { divisor: 1e3, unit: 'Kbps' };
    return { divisor: 1, unit: 'bps' };
  };

  return (
    <div className="container mt-4">
      <h1 className="text-center mb-4">用户流量查询</h1>
      <div className="mb-3">
        <Row className="justify-content-center">
          <Col xs={12} sm={8} md={6}>
            <Form.Control
              type="text"
              className="form-control"
              placeholder="输入IP地址"
              value={ip}
              onChange={(e) => setIp(e.target.value)}
            />
          </Col>
          <Col xs={12} sm={4} md="auto" className="mt-2 mt-sm-0">
            <Button variant="primary" onClick={handleIpTrafficQuery} disabled={loading} block>
              {loading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="me-2"
                  />
                  加载中...
                </>
              ) : (
                '查询流量'
              )}
            </Button>
          </Col>
        </Row>
      </div>

      {/* 显示流量图表 */}
      {trafficData && (
        <>
          <div id="trafficChart" style={{ width: '100%', height: '400px' }} className="mt-4"></div>
          <div id="connectionChart" style={{ width: '100%', height: '400px' }} className="mt-4"></div>
        </>
      )}

      {/* 错误提示弹窗 */}
      <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>错误</Modal.Title>
        </Modal.Header>
        <Modal.Body>{errorMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowErrorModal(false)}>
            关闭
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UserDashboard;
