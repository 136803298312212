import React, { useState } from 'react';
import { Form, Button, Alert, Container, Row, Col, Spinner, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import API from '../services/api'; // 导入API实例

const IPPolicyAdjustmentUser = () => {
  const [ip, setIp] = useState(''); // 存储IP地址
  const [message, setMessage] = useState(''); // 存储返回的消息
  const [error, setError] = useState(''); // 存储错误消息
  const [loading, setLoading] = useState(false); // 控制加载状态

  // 添加封UDP规则的处理函数
  const handleAddUDPBlock = async () => {
    setLoading(true);
    setMessage('');
    setError('');
    try {
      const response = await API.post('/api/user/block-udp', { ip });
      setMessage(response.data.message); // 成功消息
    } catch (err) {
      setError(err.response?.data?.message || '添加封UDP规则时出错');
    }
    setLoading(false);
  };

  // 删除封UDP规则的处理函数
  const handleDeleteUDPBlock = async () => {
    setLoading(true);
    setMessage('');
    setError('');
    try {
      const response = await API.post('/api/user/delete-block-udp', { ip });
      setMessage(response.data.message); // 成功消息
    } catch (err) {
      setError(err.response?.data?.message || '删除封UDP规则时出错');
    }
    setLoading(false);
  };

  // 添加封海外规则的处理函数
  const handleAddBlockNonChina = async () => {
    setLoading(true);
    setMessage('');
    setError('');
    try {
      const response = await API.post('/api/user/apply-rule-block-non-china', { ip });
      setMessage(response.data.message); // 成功消息
    } catch (err) {
      setError(err.response?.data?.message || '添加封海外规则时出错');
    }
    setLoading(false);
  };

  // 删除封海外规则的处理函数
  const handleDeleteBlockNonChina = async () => {
    setLoading(true);
    setMessage('');
    setError('');
    try {
      const response = await API.post('/api/user/delete-apply-rule-block-non-china', { ip });
      setMessage(response.data.message); // 成功消息
    } catch (err) {
      setError(err.response?.data?.message || '删除封海外规则时出错');
    }
    setLoading(false);
  };

  return (
    <Container className="my-5">
      <h1 className="my-4 text-center">IP策略调整</h1>

      {/* 成功或错误提示 */}
      {message && <Alert variant="success">{message}</Alert>}
      {error && <Alert variant="danger">{error}</Alert>}

      <Card className="shadow p-4 mb-5 bg-white rounded">
        <Card.Body>
          <Card.Title className="text-center mb-3 display-6">IP操作面板</Card.Title>

          {/* 提示框 */}
          <Alert variant="info" className="text-center">
            此功能仅限云主机和托管服务器的IP使用
          </Alert>

          <Form>
            <Form.Group controlId="formIP">
              <Form.Label>请输入IP地址</Form.Label>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>请输入有效的服务器IP</Tooltip>}
              >
                <Form.Control
                  type="text"
                  placeholder="例如: 192.168.0.1"
                  value={ip}
                  onChange={(e) => setIp(e.target.value)}
                  className="mb-4"
                  style={{ fontSize: '1rem' }} // 调整字体大小
                />
              </OverlayTrigger>
            </Form.Group>

            <Row>
              <Col xs={12} md={6} className="mb-3">
                {/* 添加封UDP按钮 */}
                <Button
                  variant="primary"
                  onClick={handleAddUDPBlock}
                  disabled={loading || !ip}
                  className="w-100"
                  style={{ fontSize: '0.9rem', padding: '0.6rem' }} // 调整字体大小和间距
                >
                  {loading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    /> 
                  ) : (
                    <>
                      <i className="fas fa-shield-alt"></i> 添加封UDP规则
                    </>
                  )}
                </Button>
              </Col>

              <Col xs={12} md={6} className="mb-3">
                {/* 删除封UDP按钮 */}
                <Button
                  variant="danger"
                  onClick={handleDeleteUDPBlock}
                  disabled={loading || !ip}
                  className="w-100"
                  style={{ fontSize: '0.9rem', padding: '0.6rem' }} // 调整字体大小和间距
                >
                  {loading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    /> 
                  ) : (
                    <>
                      <i className="fas fa-trash-alt"></i> 删除封UDP规则
                    </>
                  )}
                </Button>
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={6} className="mb-3">
                {/* 添加封海外按钮 */}
                <Button
                  variant="primary"
                  onClick={handleAddBlockNonChina}
                  disabled={loading || !ip}
                  className="w-100"
                  style={{ fontSize: '0.9rem', padding: '0.6rem' }} // 调整字体大小和间距
                >
                  {loading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    /> 
                  ) : (
                    <>
                      <i className="fas fa-shield-alt"></i> 添加封海外规则
                    </>
                  )}
                </Button>
              </Col>

              <Col xs={12} md={6} className="mb-3">
                {/* 删除封海外按钮 */}
                <Button
                  variant="danger"
                  onClick={handleDeleteBlockNonChina}
                  disabled={loading || !ip}
                  className="w-100"
                  style={{ fontSize: '0.9rem', padding: '0.6rem' }} // 调整字体大小和间距
                >
                  {loading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    /> 
                  ) : (
                    <>
                      <i className="fas fa-trash-alt"></i> 删除封海外规则
                    </>
                  )}
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default IPPolicyAdjustmentUser;
